<template>
  <v-container align="center" fluid class="d-flex justify-center mb-15">
    <v-row class="d-flex justify-center">
      <v-col cols="12" class="d-flex justify-center pb-0">
        <v-card width="850" elevation="3" id="dataapp">
          <v-card-title class="ml-10 mt-5"> </v-card-title>

          <v-card-text>
            <v-row class="ml-10" style="border-bottom: 1px groove">
              <v-col cols="9" class="mt-7">
                <v-row class="d-flex flex-column">
                  <v-col class="pt-0">
                    <p style="font-size: 2.1rem; color: #757575">CHALAN</p>
                    <!-- <p class="mb-0 text-h-1">INVOICE</p> -->
                  </v-col>
                  <v-col class="py-0"
                    ><p class="font-weight-medium py-0 mb-0">
                      BILL FROM :
                    </p></v-col
                  >
                  <v-col class="pb-0"
                    ><p class="mb-0 text-body-1">
                      {{ companyinfo.name }}
                    </p></v-col
                  >
                  <v-col cols="4" class="py-0">
                    <p class="text-body-1 mb-0">{{ companyinfo.address }}</p>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="2" align-self="center">
                <div style="height: 70%; width: 70%">
                  <v-img
                    :src="companyinfo.logo_url"
                    alt="Rooster crowing"
                  ></v-img>
                </div>
              </v-col>
            </v-row>

            <v-row class="ml-10 pa-0 mt-5">
              <v-col class="py-0 mb-0" cols="8"
                ><p class="mb-0 font-weight-medium">BILL TO :</p></v-col
              >
              <v-col cols="4" class="py-0 d-flex flex-row">
                <p class="text-body-1 mb-0">CHALAN #</p>
                <v-spacer></v-spacer>
                <p class="text-body-1 mb-0 mr-13">{{ billTo.id }}</p>
              </v-col>

              <v-col cols="8" class="py-0">
                <p class="text-body-1 mb-0 mt-2">
                  {{ billTo.name }}
                </p>
              </v-col>
              <v-col cols="4" class="py-0 d-flex flex-row">
                <v-col class="py-0 d-flex flex-row pl-0 mr-5">
                  <p class="text-body-1 mb-0">Date:</p>
                  <v-spacer></v-spacer>
                  <p class="text-body-1 mb-0 mr-5">
                    {{ new Date(billTo.date).toDateString() }}
                  </p>
                </v-col>
              </v-col>

              <!-- <v-col cols="2" class="py-0">
              <p class="text-body-1"></p>
            </v-col> -->

              <v-col cols="8" class="py-0">
                <p class="text-body-1 mb-0">{{ billTo.location }}</p>
              </v-col>
              <v-col cols="4" class="py-0" dense>
                <div
                  class="d-flex flex-row align-center mr-12"
                  style="background-color: #cfd3d4"
                >
                  <p class="mt-2 mb-1 font-weight-medium ml-2 mb-2">
                    {{ billTo.chalan_status }}
                  </p>
                </div>
              </v-col>
            </v-row>
          
            <div>
              <v-card min-height="500" elevation="0" class="mt-2">
                <v-data-table
                  :headers="headers"
                  :items="orderAllArray"
                  item-key="id"
                  class="elevation-0 mx-12"
                  hide-default-footer
                  dense
                >
                </v-data-table>
              </v-card>
            </div>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="3">
                <v-btn
                  color="primary"
                  dark
                  @click="AllConfirm"
                  :disabled="confirmbtn != true"
                >
                  All Confirm
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="d-flex justify-center py-0">
        <v-card elevation="0" class="mt-0">
          <v-btn
            text
            @click="printData"
            class="pa-0"
            style="padding-left: 5px !important"
          >
            <v-icon class="pr-1" large color="black">mdi-printer </v-icon>
            Print
          </v-btn>
          <v-btn
            text
            @click="printData"
            style="padding-left: 0px !important; padding-right: 10px !important"
          >
            <v-icon class="pr-0" large color="black">mdi-download </v-icon>
            download
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
       


<script>
export default {
  data: () => ({
    orderAllArray: [],

    companyinfo: {},

    snackbar: false,
    text: "",

    color: "",
    billFromInfo: {},
    billTo: {},
    quantity: "",
    approve_quantity: 0,
    damage_quantity: 0,
    not_found_quantity: 0,
    value_store: [],
    confirmbtn: false,
    quantityform: false,
    contact: {},
    user: {},
    headers: [
      {
        text: "Name",
        sortable: false,
        value: "product_name",
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: "Barcode",
        sortable: false,
        value: "product_barcode",
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: "Color",
        value: "product_color",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: "Size",
        value: "product_size",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: " Variant",
        value: "product_variant",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader ",
      },
      // {
      //   text: " Status",
      //   value: "transaction_status",
      //   sortable: false,
      //   class: "blue-grey lighten-4 text-body-2 tableheader ",
      // },

      {
        text: "Requested ",
        value: "credit",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },

      {
        text: "Approved ",
        value: "approved_qty",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: "Damaged ",
        value: "damaged_qty",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: "Not Found ",
        value: "not_found_qty",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },

      //   // {
      //   //   text: "Discount ",
      //   //   value: "total_discount",
      //   //   sortable: false,
      //   //   class: "blue-grey lighten-4 text-body-2 ",
      //   // },
      //   {
      //     text: "Amount",
      //     value: "total_price",
      //     sortable: false,
      //     class: "blue-grey lighten-4 text-body-2 tableheader",
      //   },
    ],
  }),

  methods: {
    initialize(item) {
      axios
        .get(`manager/show_specific_chalan/${this.$route.query.id}/`)
        .then((response) => {
          this.orderAllArray = response.data.data.transaction_info;
          // console.log("this is all data", this.orderAllArray);

          //   this.billFromInfo = response.data.request_from;
          this.billTo = response.data.data;
          //   this.orderinfo = response.data.order_data;

          this.companyinfo = response.data.data.company_info;
          //   this.userdata = response.data.billing_address_data;
          //console.log("table info", this.orders);
          // console.log("this is company data", response.data);
        });
    },

    //   printData() {
    // //   var divToPrint = document.getElementById("dataapp");
    // //   var style = "<style>";
    // //   style = style + "table {width: 100%;font: 17px Calibri;}";
    // //   style =
    // //     style +
    // //     "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    // //   style = style + "padding: 2px 3px;text-align: center;}";
    // //   style = style + "</style>";

    // //   var win = window.open("", "", "height=1900,width=1900");
    // //   win.document.write(style); //  add the style.
    // //   win.document.write(divToPrint.outerHTML);
    // //   win.document.close();
    // //   win.print();
    // window.print();
    // },

    // printData() {
    //   var printContents = document.getElementById("dataapp").innerHTML;
    //   var originalContents = document.body.innerHTML;

    //   document.body.innerHTML = printContents;
    //   window.print();

    //   document.body.innerHTML = originalContents;
    // },
    printData() {
      //this.$htmlToPaper("dataapp");
      this.$htmlToPaper("dataapp");
    },

    Approveqty(item) {
      let datas = {
        specification_id: item.specification_id,
        product_id: item.product_id,
        approved_qty: parseInt(item.approved_qty),
        damaged_qty: parseInt(item.damaged_qty),
        not_found_qty: parseInt(item.not_found_qty),
        transaction_id: item.id,
        requested_qty: parseInt(item.requested_qty),
      };
      this.value_store.push(datas);
      this.confirmbtn = true;

      this.orderAllArray = this.orderAllArray.filter(
        (contact) => contact.id != item.id
      );
      // console.log("this is after", this.value_store);
    },
    AllConfirm() {
      // console.log(this.value_store);
      axios
        .post(
          `manager/approve_chalan/1/${this.$route.query.id}/${this.user.user_id}/`,
          this.value_store
        )
        .then((response) => {
          if (response.data.success) {
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            window.history.back();
          } else {
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "something want wrong!";
          this.color = "error";
          this.snackbar = true;
        });
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped>
.tableheader {
  max-height: 32px !important;
}
/* tbody tr:nth-of-type(even) {
  background-color: #e3f2fd !important;
} */
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr :nth-child(even){
 
  background-color: #ff7743;
 
}
table.v-table thead tr{
 max-height:1px !important;
} */
/* 
table.v-table thead tr:nth-child(even) {
  background-color: #dddddd;
} */
/* .invoice{
  background: #f5f5f5; 
  font-size: 10em
}
.invoice .theme--light.v-table{
  background-color: #00f !important;
} */
</style>